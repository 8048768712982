<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="''">
          <template v-slot:preview>
            <b-card no-body no-footer class="full-width">
              <!-- BEGIN WIZARD -->
              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!-- NAVIGATION -->
                <div class="wizard-nav bg-nav-light">
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin khách hàng</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử hoá đơn</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử đơn hàng</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử thu cũ</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- CONTENT -->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-10 px-lg-5">
                      <div class="col-xl-12">
                        <form class="form mt-0">
                          <div
                            class="full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-md-12 full-width">
                              <b-row class="mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Họ:</label>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="customer.lastName"
                                      required
                                      placeholder="Nhập tên lót"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Tên:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.customer.fullName.$model"
                                      :state="validateState('fullName')"
                                      required
                                      placeholder="Nhập tên"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Vui lỏng nhập tên khách
                                      hàng</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Điện thoại:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.customer.phoneNo.$model"
                                      :state="validateState('phoneNo')"
                                      required
                                      :disabled="true"
                                      placeholder="Nhập điện thoại"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Số điện thoại không thể để
                                      trống</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Email:</label>
                                    </template>
                                    <b-form-input
                                      type="email"
                                      size="sm"
                                      v-model="$v.customer.email.$model"
                                      :state="validateState('email')"
                                      required
                                      placeholder="Nhập email"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Email không đúng định
                                      dạng</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <div class="row mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Ngày sinh:</label>
                                    </template>
                                    <date-picker
                                      placeholder="Chọn ngày sinh"
                                      class="form-control form-control-sm"
                                      :config="dpConfigs.date"
                                      v-model="customer.dateOfBirth"
                                    ></date-picker>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Giới tính:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.gender"
                                      :options="listGender"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Loại khách hàng:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.type"
                                      :options="listType"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Chọn loại khách</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Nhân viên phụ trách:</label>
                                    </template>
                                    <vue-autosuggest
                                      v-model="customer.inChargeEmployeeName"
                                      :suggestions="filteredOptionEmployee"
                                      @selected="onSelectedEmployee"
                                      :limit="10"
                                      @input="onInputChangeEmployee"
                                      :input-props="{
                                        id: 'autosuggest__input',
                                        placeholder: 'Chọn nhân viên',
                                      }"
                                    >
                                      <div
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        {{ suggestion.item.suggestionName }}
                                      </div>
                                    </vue-autosuggest>
                                  </b-form-group>
                                </b-col>
                              </div>
                              <b-row class="mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Thành phố:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.city"
                                      v-on:change="getDistricts(true)"
                                      :options="listCity"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Thành phố --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Quận:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.district"
                                      :options="listDistrict"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                      v-on:change="getWards(true)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Quận --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Phường:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.ward"
                                      :options="listWard"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Phường --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>

                                <b-col cols="3">
                                  <b
                                    >Điểm tích lũy:
                                    <span class="text-primary">{{
                                      customer.totalPoint
                                    }}</span>
                                  </b>
                                </b-col>
                              </b-row>
                              <b-row class="mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Địa chỉ:</label>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="customer.address"
                                      placeholder="Nhập địa chỉ"
                                      required
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Ghi chú:</label>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="customer.note"
                                      :placeholder="'Thêm ghi chú'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>
                            <b-col cols="12">
                              <b-button
                                style="width: 70px"
                                variant="primary"
                                size="sm"
                                type="submit"
                                v-if="checkPermission('CUSTOMER_UPDATE')"
                                @click="updateCustomer"
                                >Lưu</b-button
                              >
                              <b-button
                                class="ml-2"
                                style="width: 70px"
                                @click="$router.go(-1)"
                                variant="secondary"
                                size="sm"
                                >Hủy</b-button
                              >
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerBill
                                  :customerId="$route.query.phoneNo"
                                />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerOrder :customerId="$route.query.id" />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerTradeIn
                                  :customerId="$route.query.id"
                                />
                              </b-row>
                            </b-col>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END WIZARD -->
            </b-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget .dropdown-menu {
  width: 21em;
}
.card-header-tabs {
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
.label-header-form {
  font-size: 16px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
textarea.form-control {
  border: 1px solid #ced4da;
}
.bg-nav-light {
  background-color: #eef0f8;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { checkPermission } from '../../../utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import CustomerBill from '../../components/CustomerBill.vue';
import CustomerOrder from '../../components/CustomerOrder.vue';
import CustomerTradeIn from '../../components/CustomerTradeIn.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import { VueAutosuggest } from 'vue-autosuggest';
import { xoa_dau } from './../../../utils/common';
import { CUSTOMER_GENDER, CUSTOMER_TYPE } from '../../../utils/constants';
import { makeToastSuccess, makeToastFaile } from '../../../utils/common';
export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      isNew: true,
      listType: CUSTOMER_TYPE,
      listCity: [],
      listDistrict: [],
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listEmployee: [],
      filteredOptionEmployee: [],
      optionEmployee: [
        {
          data: [],
        },
      ],
      customer: {
        id: null,
        fullName: null,
        phoneNo: null,
        gender: null,
        dateOfBirth: null,
        note: null,
        address: null,
        email: null,
        type: null,
        city: null,
        district: null,
        ward: null,
        inChargeEmployeeId: null,
        lastName: null,
        totalPoint: 0,
        inChargeEmployeeName: null,
      },
    };
  },
  validations: {
    customer: {
      fullName: { required },
      phoneNo: { required },
      email: { email },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    VueAutosuggest,
    CustomerBill,
    CustomerOrder,
    CustomerTradeIn,
  },
  mounted() {
    if (this.checkPermission('CUSTOMER_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Khách hàng', route: '/customers' },
        { title: 'Danh sách khách hàng', route: '/customers' },
        { title: 'Cập nhật khách hàng' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Khách hàng', route: '/customers' },
        { title: 'Danh sách khách hàng', route: '/customers' },
        { title: 'Thông tin khách hàng' },
      ]);
    }

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  created() {
    this.getListEmployee();
    this.getCities();
    this.getCustomerById();
  },
  methods: {
    checkPermission,
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.customer[name];
        return $dirty ? !$error : null;
      }
    },
    updateCustomer: function () {
      this.isNew = false;

      this.$v.customer.$touch();
      if (this.$v.customer.$anyError) {
        return;
      }

      const dateOfBirth = this.customer.dateOfBirth
        ? moment(this.customer.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;

      const data = {
        ...this.customer,
        dateOfBirth: dateOfBirth,
      };
      ApiService.setHeader();
      ApiService.put(`customer`, data)
        .then(() => {
          makeToastSuccess('Cập nhật thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getListEmployee: function () {
      this.optionEmployee[0].data = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          const store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee[0].data.push(store);
        });
        this.filteredOptionEmployee = [{ data: this.optionEmployee[0].data }];
      });
    },
    getCustomerById: function () {
      this.customerId = this.$route.query.id;
      ApiService.setHeader();
      ApiService.get(`customer/${this.customerId}`).then((response) => {
        let dataset = response.data.data;
        this.customer = {
          ...dataset,
        };
        this.getDistricts();
        this.getWards();
      });
    },
    getCities: function () {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistricts: function (reload) {
      if (reload) {
        this.customer.district = null;
        this.customer.ward = null;
      }

      if (!this.customer.city) {
        this.listDistrict = [];
        this.listWard = [];
        return;
      }

      ApiService.setHeader();
      ApiService.get(`city/${this.customer.city}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWards: function (reload) {
      if (reload) {
        this.customer.ward = null;
      }

      if (!this.customer.district) {
        this.listWard = [];
        return;
      }

      if (this.customer.district) {
        ApiService.setHeader();
        ApiService.get(`city/${this.customer.district}/ward`).then((data) => {
          this.listWard = data.data.data;
        });
      }
    },
    onSelectedEmployee(option) {
      this.customer.inChargeEmployeeName = option.item.name;
      this.customer.inChargeEmployeeId = option.item.id;
    },
    onInputChangeEmployee(text = '') {
      const filteredData = this.optionEmployee[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
